import React, { useEffect } from "react"

import { useMediaQuery } from "react-responsive"

import "./Footer.scss"
const Footer = () => {
  return (
    <div className="Footer">
      {/* <div className="Footer_img" /> */}
      <div className="Footer_red"/>
      <div>
        © 2020 u5a. All Rights Reserved | Web Design @{" "}
        <a href="http://u5a.in/" target="_blank" rel="noreferrer">
          u5a Inc.
        </a>{" "}
        | Web Development @{" "}
        <a href="http://ctrlnss.com/" target="_blank" rel="noreferrer">
          CtrlN Software Solutions
        </a>
      </div>
    </div>
  )
}

export default Footer
