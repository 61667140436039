import React from "react"
import Header from "../Header/Header1"
import Footer from "../Footer/Footer"
import "./Layout.scss"
import "../Footer/Footer.scss"

const Layout = ({ children }) => {
  return (
    <div
      className="overall"
      onContextMenu={e => {
        e.preventDefault()
        return false
      }}
    >
      <Header />
      <div className="Layout00">
      <div className="Layout">{children}</div>
      <div className="Footer_img" />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
