import React, { useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Button from "@material-ui/core/Button"
import { CgMenuLeftAlt } from "react-icons/cg"
import "./Header.scss"

import U5A from "../../images/Header/pictorial and textual logo.png"

import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"

import { Link } from "gatsby"

const useStyles = makeStyles({
  list: {
    width: 200,
  },
  fullList: {
    width: "auto",
  },
})

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles()

  const [left, setLeft] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setLeft(open)
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
       
        {[
          "Home",
          "Services",
          "Trainings",
          "Gallery",
          "About Us",
          "Contact",
          "Pay",
          "Feedback"
        ].map(name => {
          var name1 = name.split(" ")[0]
          if (name === "Home") name1 = ""
          return (
            <Link
              to={`/${name1}`}
              style={{ textDecoration: "none", color: "grey" }}
              activeStyle={{ color: "black" }}
            >
              <ListItem button>
                <h5>{name}</h5>
              </ListItem>
              <Divider />
            </Link>
          )
        })}
        <Link
          to="https://m.facebook.com/ch.s.srinivas"
          style={{ textDecoration: "none", color: "grey" }}
          activeStyle={{ color: "black" }}
        >
          <ListItem button>
            <h5>Facebook</h5>
          </ListItem>
          <Divider />
        </Link>
       
      </List>
    </div>
  )

  return (
    <div style={{ display: "flex" }}>
      <Button onClick={toggleDrawer(true)}  >
        <CgMenuLeftAlt size="2rem" />
      </Button>
      <div className="HeaderContainer">
        <Link to="/">
          <img src={U5A} className="HeaderImage" alt="U5A" />
        </Link>
      </div>
      <SwipeableDrawer
        anchor="left"
        open={left}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list(left)}
      </SwipeableDrawer>
    </div>
  )
}
